import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import styled from '@emotion/styled'
import { C, U, B, E, SmallCross, Hamburger, LargeCross } from '~components/Svg'
import Button from '~components/Button'
import { useSiteState } from '~context/siteContext'
import useSiteSettings from '~utils/useSiteSettings'
import DataViewer from '~utils/DataViewer'
import resolveLink from '~utils/resolveLink'
import AnimatedNavButton from '~components/AnimatedNavButton'
import { motion, useAnimation, AnimateSharedLayout } from 'framer-motion'
import {mobile, tablet } from '~styles/global'
import Contact from '~components/Contact'
import { Global, css } from '@emotion/react'

const Header = ({ className, location }) => {

	const [siteState, setSiteState] = useSiteState()
	const { mainMenu, contactHeading, southMelbourneAddress, essendonAddress, phoneNumber, faxNumber, email, hours, contactFormHeading, contactFormText } = useSiteSettings()
	const [isSelected, setIsSelected] = useState()

	const toogleMobileMenu = () => {
		if (siteState.menuOpen){
			setSiteState(prevState => ({
				...prevState,
				menuOpen: false,
				contactOpen: false
			}))
		} else {
			setSiteState(prevState => ({
				...prevState,
				menuOpen: true,
			}))
		}
	}

	return(
		<>
			{(siteState.menuOpen || siteState.contactOpen) &&
			<Global styles={css`
					html, body{
						overflow: hidden;
					}
			`} />
			}
			<Wrap className={className}>
				<Top open={siteState.menuOpen || siteState.contactOpen}>
					<Navigation>
						<AnimateSharedLayout>
							{mainMenu.map(menuItem => (
								<AnimatedNavButton 
									small 
									key={menuItem._key} 
									link={menuItem?.link} 
									className='nav'
									isSelected={resolveLink(menuItem?.link?.document) === location?.pathname && !siteState.contactOpen}
								>
									{menuItem.text}
								</AnimatedNavButton>
							))}
						</AnimateSharedLayout>
						<ContactLink>
							<ContactButton 
								small 
								black 
								className='nav'
								onClick={() => setSiteState(prevState => ({
									...prevState,
									contactOpen: !siteState.contactOpen
								}))} 
							>
								{siteState.contactOpen ? 
									<StyledLargeCross />
									:
									'Contact'
								}
							</ContactButton>
							<GradientBacking/>
						</ContactLink>
					</Navigation>
					<MobileMenuToggle 
						onClick={() => toogleMobileMenu()}>
						<StyledMobileCross show={siteState.menuOpen || siteState.contactOpen}/>
						<StyledMobileHamburger show={!siteState.menuOpen && !siteState.contactOpen}/>
					</MobileMenuToggle>
				</Top>
			</Wrap>
			<Contact 
				content={{contactHeading, southMelbourneAddress, essendonAddress, phoneNumber, faxNumber, email, hours, contactFormHeading, contactFormText}}
				show={siteState.contactOpen}
			/>
			<BottomBar open={siteState.menuOpen || siteState.contactOpen}/>
			<MobileMenu open={siteState.menuOpen}>
				<MobMenuInner>
					{mainMenu.map((menuItem, i) => (
						<motion.div 
							key={menuItem._key}
							initial="hidden"
							animate={siteState.menuOpen ? 'visible' : 'hidden'}
							variants={{
								visible: { 
									opacity: 1,	
									transition: {
										delay: (i + 1) * 0.1,
										duration: 0.7
									} 
								},
								hidden: { opacity: 0}
							}}
						>
							<NavButton medium activeClassName='active' black className='nav' to={resolveLink(menuItem?.link?.document)}>{menuItem.text}</NavButton>
						</motion.div>
					))}
					<motion.div 
						initial="hidden"
						animate={siteState.menuOpen ? 'visible' : 'hidden'}
						variants={{
							visible: { 
								opacity: 1,	
								transition: {
									delay: 0.7,
									duration: 0.7
								} 
							},
							hidden: { opacity: 0}
						}}
					>
						<ContactLink>
							<ContactButton 
								black
								medium 
								className='nav'
								onClick={() => setSiteState(prevState => ({
									...prevState,
									contactOpen: true
								}))} 
								activeClassName='active'>
									Contact
							</ContactButton>
							<GradientBacking/>
						</ContactLink>
					</motion.div>
				</MobMenuInner>
			</MobileMenu>
			{/* <DataViewer data={{mainMenu, contactHeading, southMelbourneAddress, essendonAddress, phoneNumber, faxNumber, email, hours, contactFormHeading, contactFormText}} name="page"/> */}
		</>
	)
}

const Wrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
	bottom: 0;
	left: 0;
  z-index: 100;
	pointer-events: none;
	box-sizing: border-box;
`
const Top = styled.div`
	display: flex;
	justify-content: center;
	padding: 27px 25px 25px;
	align-items: center;
	transition:${ props => props.open ? 'none' : 'background-color 0.3s'};
	${tablet}{
		padding: 0;
		background-color: ${ props => props.open ? 'transparent' : 'var(--white)'};
		height: 79px;
	}
`

const Navigation = styled.nav`
	background-color: var(--white);
	border-radius: 40px;
	pointer-events: all;
	height: 62px;
	display: flex;
	align-items: center;
	padding: 0 14px;
	${tablet}{
		display: none;
	}
`
const NavButton = styled(Button)`
	${tablet}{
		margin-right: 0;
		margin-bottom: 6px;
		min-width: 115px;
	}
`
const ContactButton = styled(Button)`
	position: relative;
	z-index: 1;
	min-width: 98px;
	&.active, :hover{
		background-color: transparent;
		color: var(--white)
	}
	${tablet}{
		min-width: 115px;
		background-color: #2F2F2F;
	}
`
const ContactLink = styled.div`
	position: relative;
`
const StyledLargeCross = styled(LargeCross)`
	width: 16px;
`
const GradientBacking = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: var(--greenGradient);
	border-radius: 30px;
`
const MobileMenuToggle = styled.button`
	pointer-events: all;
	display: none;
	${tablet}{
		display: block;
	}
`
const StyledMobileCross = styled(SmallCross)`
	max-width: 26px;
	display: ${ props => props.show ? 'block' : 'none'};
	color: var(--white);
	width: 100%;
`
const StyledMobileHamburger = styled(Hamburger)`
	max-width: 30px;
	display: ${ props => props.show ? 'block' : 'none'};
	width: 100%;
`
const MobileMenu = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 2;
	background-color: var(--black);
	opacity: ${ props => props.open ? '1' : '0'};
	pointer-events: ${ props => props.open ? 'all' : 'none'};
	transition: opacity 0.25s;
	align-items: center;	
	justify-content: center;
	display: none;
	${tablet}{
		display: flex;
	}
`
const MobMenuInner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const BottomBar = styled.div`
	transition:${ props => props.open ? 'none' : 'background-color 0.3s'};
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	${tablet}{
		padding: 0;
		background-color: ${ props => props.open ? 'transparent' : 'var(--white)'};
		height: 79px;
	}
`
Header.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
}

export default Header
